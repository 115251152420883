import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from '../../utils/axios';
import { KeyCheatType, KeyCheatTypeAdd } from '../../types/keyCheat';

export const fetchKeyCheats = createAsyncThunk(
  'keyCheats/fetchKeyCheats',
  async () => {
    const response = await axiosServices.get('/keycheats'); // Thay đổi URL nếu cần
    return response.data;
  }
);

export const updateKeyCheat = createAsyncThunk(
  'keyCheats/updateKeyCheat',
  async (keyCheat: KeyCheatType) => {
    const response = await axiosServices.put(`/keycheats/${keyCheat._id}`, keyCheat);
    return response.data;
  }
);

export const addKeyCheat = createAsyncThunk(
  'keyCheats/addKeyCheat',
  async (keyCheat: KeyCheatTypeAdd) => {
    try {
      const response = await axiosServices.post(`/keycheats`, keyCheat);
      return response.data;
    } catch (error) {
      console.error('Error adding key cheat:', error); // Log lỗi nếu có
      throw error; // Đảm bảo lỗi được ném ra để Redux có thể xử lý
    }
  }
);

export const deleteKeyCheat = createAsyncThunk(
  'keyCheats/deleteKeyCheat',
  async (keyCheatId: string) => {
    await axiosServices.delete(`/keycheats/${keyCheatId}`);
    return keyCheatId;
  }
);