import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAccPubgs, updateAccPubg, addAccPubg, deleteAccPubg } from '../thunks/accPubgThunks';
import { AccPubgType } from '../../types/accPubg';

interface AccPubgState {
  accPubgs: AccPubgType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: AccPubgState = {
  accPubgs: [],
  status: 'idle',
  error: null,
};

const accPubgSlice = createSlice({
  name: 'accPubgs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccPubgs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccPubgs.fulfilled, (state, action: PayloadAction<AccPubgType[]>) => {
        state.status = 'succeeded';
        state.accPubgs = action.payload;
      })
      .addCase(fetchAccPubgs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(updateAccPubg.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAccPubg.fulfilled, (state, action: PayloadAction<AccPubgType>) => {
        state.status = 'succeeded';
        const index = state.accPubgs.findIndex(accPubg => accPubg._id === action.payload._id);
        if (index !== -1) {
          state.accPubgs[index] = action.payload;
        }
      })
      .addCase(updateAccPubg.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(addAccPubg.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addAccPubg.fulfilled, (state, action: PayloadAction<AccPubgType>) => {
        state.status = 'succeeded';
        state.accPubgs.push(action.payload); // Add the new key cheat to the state
      })
      .addCase(addAccPubg.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(deleteAccPubg.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAccPubg.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = 'succeeded';
        state.accPubgs = state.accPubgs.filter(accPubg => accPubg._id !== action.payload); // Remove the deleted key cheat
      })
      .addCase(deleteAccPubg.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      });
  },
});

export default accPubgSlice.reducer;
