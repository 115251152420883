// src/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';
import { persistStore } from 'redux-persist';

import userReducer from './slices/userSlice';
import accountReducer from './slices/accountSlice';
import keyCheatReducer from './slices/keyCheatSlice';
import bannerReducer from './slices/bannerSlice';
import accPubgReducer from './slices/accPubgSlice';
import snackbarReducer from './slices/snackbarSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        account: accountReducer,
        keyCheats: keyCheatReducer,
        banner: bannerReducer,
        listAcc: accPubgReducer,
        snackbar: snackbarReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
    }),
});

const persister = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persister };
