import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from '../../utils/axios';
import { BannerType, BannerTypeAdd } from '../../types/banner';

export const fetchBanners = createAsyncThunk(
  'banners/fetchBanners',
  async () => {
    const response = await axiosServices.get('/banners'); // Thay đổi URL nếu cần
    return response.data;
  }
);

export const updateBanner = createAsyncThunk(
  'banners/updateBanner',
  async (banner: BannerType) => {
    const response = await axiosServices.put(`/banners/${banner._id}`, banner);
    return response.data;
  }
);

export const addBanner = createAsyncThunk(
  'banners/addBanner',
  async (banner: BannerTypeAdd) => {
    try {
      const response = await axiosServices.post(`/banners`, banner);
      return response.data;
    } catch (error) {
      console.error('Error adding key cheat:', error); // Log lỗi nếu có
      throw error; // Đảm bảo lỗi được ném ra để Redux có thể xử lý
    }
  }
);

export const deleteBanner = createAsyncThunk(
  'banners/deleteBanner',
  async (bannerId: string) => {
    await axiosServices.delete(`/banners/${bannerId}`);
    return bannerId;
  }
);