// src/components/SaleSticker.tsx
import React from 'react';

interface SaleStickerProps {
    discount: number;
}

const SaleSticker: React.FC<SaleStickerProps> = ({ discount }) => {
    return (
       <img src='/assets/card/hot.gif' style={{width:'100%'}}/>
    );
};

export default SaleSticker;
