import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomContainer from '../../layout/components/Container';
import { formatCurrency } from '../../layout/components/formatCurrency';
import { RootState, useDispatch, useSelector } from '../../store';
import { fetchAccPubgs } from '../../store/thunks/accPubgThunks';
import ImageSwiper from './components/Swiper';
import CardAcc from './components/CardAcc';

const Detail: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  const listAcc = useSelector((state: RootState) => state.listAcc.accPubgs);
  const statusAcc = useSelector((state: RootState) => state.listAcc.status);
  const [data, setData] = useState<any[]>([]);
  const [dataPlus, setDataPlus] = useState<any[]>([]);

  useEffect(() => {
    if (statusAcc === 'idle') {
      dispatch(fetchAccPubgs());
    }
  }, [dispatch, statusAcc]);

  useEffect(() => {
    // Kiểm tra kiểu dữ liệu của listAcc
    if (Array.isArray(listAcc)) {
      // Tìm dữ liệu hiện tại dựa trên account_id
      const currentData = listAcc.find((item: any) => item.account_id === id);
      setData(currentData ? [currentData] : []);

      if (currentData) {
        // Lọc dữ liệu dựa trên giá gần bằng nhau với khoảng cách là 1 triệu
        const filteredData = listAcc.filter((item: any) => {
          return (
            item.account_id !== id && // Loại trừ chính nó
            isPriceSimilar(item.price, currentData.price, 2000000) // So sánh với khoảng cách 1 triệu
          );
        });

        setDataPlus(filteredData);
      }
    }

  }, [listAcc, id]);

  // Hàm so sánh mềm cho các chuỗi, trả về true nếu chuỗi tương đối giống nhau
  const compareStrings = (str1: string, str2: string) => {
    if (!str1 || !str2) return false;
    const lowerStr1 = str1.toLowerCase();
    const lowerStr2 = str2.toLowerCase();
    return lowerStr1.includes(lowerStr2) || lowerStr2.includes(lowerStr1);
  };

  // Hàm so sánh mềm cho giá, trả về true nếu giá gần bằng nhau
  const isPriceSimilar = (price1: number, price2: number, tolerance: number = 1000000) => {
    return Math.abs(price1 - price2) <= tolerance; // Điều chỉnh mức tolerance để xác định khoảng giá gần bằng
  };

  const handleClick = () => {
    window.open('https://www.facebook.com/profile.php?id=100030867711852', '_blank');
  };

  return (
    <CustomContainer>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} sm={6.8} p={2} sx={{
          boxShadow: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.24) 0px 0px 0px'
            : 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          borderRadius: '10px',
          backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.grey[800] // Màu nền cho chế độ tối
            : theme.palette.background.paper, // Màu nền cho chế độ sáng
        }}>
          <Grid maxHeight={650}>
            <ImageSwiper images={data} isPagination={true} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} p={2} sx={{
          boxShadow: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.24) 0px 0px 0px'
            : 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          borderRadius: '10px',
          backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.grey[800] // Màu nền cho chế độ tối
            : theme.palette.background.paper, // Màu nền cho chế độ sáng
        }}>
          <Typography variant='h5'>PUBG MOBILE</Typography>
          <Typography variant='body2' fontWeight={700}>ID: #{id}</Typography>
          <Divider />
          <Typography mt={1} variant='body1' fontWeight={700}>Thông tin tài khoản</Typography>
          <Grid container justifyContent='space-between'>
            <Grid>
              <Typography variant='body1'>Thiết bị</Typography>
              <Typography variant='body1'>Đăng nhập</Typography>
              <Typography variant='body1'>Thông tin</Typography>
            </Grid>
            <Grid>
              <Typography variant='body1'>{data[0]?.login_method}</Typography>
              <Typography variant='body1'>{data[0]?.login_device}</Typography>
              <Typography variant='body1'>{data[0]?.content}</Typography>
            </Grid>
          </Grid>
          <Grid item justifyContent='center' alignItems='center' mt={2} sx={{ background: '#D6E2EB', borderRadius: '10px', p: 2 }}>
            <Typography variant='body1' sx={{ textDecoration: 'line-through' }} textAlign='center'>{data[0]?.price && formatCurrency(data[0]?.price)}</Typography>
            <Typography variant='h2' color='#F473B9' textAlign='center'>{data[0]?.price > data[0]?.price_reduction ? formatCurrency(data[0]?.price_reduction) : formatCurrency(data[0]?.price)}</Typography>
            <Typography variant='body1' textAlign='center'>Rẻ vô đối, giá tốt nhất thị trường</Typography>
          </Grid>
          <Grid mt={2} container justifyContent='center' alignItems='center'>
            <Button fullWidth
              onClick={handleClick}
              sx={{
                background: '#0E3EDA', color: '#fff', '&:hover': {
                  background: '#0B2FA6',
                },
                height: 56
              }}>Liên hệ với tôi để mua</Button>
          </Grid>
        </Grid>
        {/* <Grid mt={3} xs={12}>
          <Typography textAlign='center' variant='h5'>
            TÀI KHOẢN KHÁC
          </Typography>
          <CardAcc data={dataPlus} />
        </Grid> */}
      </Grid>
    </CustomContainer>
  );
};

export default Detail;
