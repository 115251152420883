// firebase-config.ts
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBLyzsixaC65Thsbwgz2drXt2MFayI9EcQ",
    authDomain: "image-nhunhuoi.firebaseapp.com",
    projectId: "image-nhunhuoi",
    storageBucket: "image-nhunhuoi.appspot.com",
    messagingSenderId: "686907501885",
    appId: "1:686907501885:web:c31b8f5723b228f8c46954",
    measurementId: "G-2R0Q0YH254"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
