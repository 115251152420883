// src/layout/CustomContainer.tsx
import { Container } from '@mui/material';
import * as React from 'react';

interface CustomContainerProps {
    children: React.ReactNode;
}

const CustomContainer: React.FC<CustomContainerProps> = ({ children }) => {
    return (
        <Container sx={{ minHeight: '72vh', mt: 10, mb: 4 }}>
            &nbsp;
            {children}
        </Container>
    );
};

export default CustomContainer;
