import { Card, CardContent, Grid, Typography, Pagination } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SaleSticker from './SaleSticker'; // Import SaleSticker component
import { formatCurrency } from '../../../layout/components/formatCurrency';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../config/firebase-config';

export interface AccProps {
    _id: string;
    accountName: string; // Tên tài khoản
    account_id: string; // ID tài khoản
    login_device: string; // Thiết bị đăng nhập
    login_method: string; // Hình thức đăng nhập
    price: number; // Giá tiền
    content: string; // Nội dung
    main_image: string; // Ảnh sảnh (dưới dạng Base64)
    additional_images: [string]; // Danh sách ảnh phụ (dưới dạng Base64)
}

interface CardAccProps {
    data: any;
}

const CardAcc: React.FC<CardAccProps> = ({ data }) => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [currentPage, setCurrentPage] = useState(1); // State để quản lý trang hiện tại
    const [imageUrls, setImageUrls] = useState<{ [key: string]: string }>({}); // State để lưu trữ URL ảnh
    const itemsPerPage = 12; // Số lượng mục trên mỗi trang

    // Tính toán dữ liệu cho trang hiện tại
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Tính tổng số trang
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Hàm xử lý khi thay đổi trang
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const handleCardClick = (id: string) => {
        navigate(`/detail/${id}`); // Navigate to detail page with card ID
    };

    async function convertImg(url: any): Promise<string> {
        let img = '';
        const parts = url.split('/');
        const fileName = parts.pop() || '';
        const encodedPath = encodeURIComponent(fileName);
        const urlImage = `https://firebasestorage.googleapis.com/v0/b/image-nhunhuoi.appspot.com/o/${encodedPath}?alt=media`;

        // Tạo tham chiếu đến tệp trong Firebase Storage
        const pathReference = ref(storage, urlImage);

        try {
            img = await getDownloadURL(pathReference);
        } catch (error) {
            console.error('Error getting download URL:', error);
        }

        return img;
    }

    // Sử dụng useEffect để tải URL ảnh khi component được render
    useEffect(() => {
        const fetchImageUrls = async () => {
            const urls: { [key: string]: string } = {};
            for (const item of data) {
                if (item.main_image) {
                    urls[item._id] = await convertImg(item.main_image);
                }
            }
            setImageUrls(urls);
        };

        fetchImageUrls();
    }, [data]);

    return (
        <>
            <Grid container spacing={2}>
                {currentItems.map((item: any) => (
                    <Grid item xs={12} sm={8} md={3} key={item._id} style={{ position: 'relative' }}>
                        <Card
                            sx={{
                                height: '100%',
                                cursor: 'pointer', // Change cursor to pointer to indicate clickable
                                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                '&:hover': {
                                    borderColor: '#FFFF00',
                                    boxShadow: '0 0 6px #FFFF00',
                                },
                            }}
                            onClick={() => handleCardClick(item.account_id)} // Add onClick handler
                        >
                            <CardContent sx={{ padding: 0, position: 'relative', marginBottom: 1 }}>
                                <img
                                    src={imageUrls[item._id] || ''}
                                    width='100%'
                                    height='138px'
                                    alt={`Card ${item.account_id}`}
                                />
                                <Grid>
                                    <Typography variant='h5' ml={1}>{item?.account_name}</Typography>
                                    <Typography variant='body1' ml={1}>ID: {item?.account_id}</Typography>
                                    <Typography variant='body1' ml={1}>Thiết bị: {item?.login_device}</Typography>
                                    <Typography variant='body1' ml={1}>Đăng nhập: {item?.login_method}</Typography>
                                    <Typography variant='body1' ml={1}>Thông tin: {item?.content}</Typography>
                                    <Typography variant='body2' ml={1} fontWeight={900}>Giá bán: {formatCurrency(item?.price)}</Typography>
                                </Grid>
                            </CardContent>
                            {item?.isHot &&
                                <Grid sx={{ position: 'absolute', top: -15, right: -15, width: 70, height: 70 }}>
                                    <SaleSticker discount={item.discount} />
                                </Grid>
                            }
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Pagination component */}
            <Grid container justifyContent="center" mt={2}>
                <Pagination
                    count={totalPages} // Tổng số trang
                    page={currentPage} // Trang hiện tại
                    onChange={handlePageChange} // Hàm thay đổi trang
                    variant="outlined" // Kiểu dáng của Pagination
                    color="primary" // Màu sắc của Pagination
                    showFirstButton // Hiển thị nút trang đầu tiên
                    showLastButton // Hiển thị nút trang cuối cùng
                />
            </Grid>
        </>
    );
};

export default CardAcc;
