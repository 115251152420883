import { Autocomplete, Box, Grid, InputAdornment, Slider, TextField, Typography } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import CustomContainer from '../../layout/components/Container';
import { RootState, useDispatch, useSelector } from '../../store';
import { fetchAccPubgs } from '../../store/thunks/accPubgThunks';
import CardAcc from './components/CardAcc';
import ImageSwiper from './components/Swiper';

const sortOptions = [
  { label: 'Giá thấp nhất', value: 'price_asc' },
  { label: 'Giá cao nhất', value: 'price_desc' },
  { label: 'Đã bán', value: 'sold' },
];

const marks = [
  { value: 0, label: '0M' },       // 0 đồng
  { value: 25, label: '250M' },    // 250 triệu
  { value: 50, label: '500M' },    // 500 triệu
  { value: 100, label: '1B' },     // 1 tỷ
];

function scaleValue(value: number) {
  if (value <= 25) return value * 10000000; // Từ 0 đến 25 tương ứng với 0 đến 250 triệu
  if (value <= 50) return (value - 25) * 10000000 + 250000000; // Từ 25 đến 50 tương ứng với 250 triệu đến 500 triệu
  return (value - 50) * 20000000 + 500000000; // Từ 50 đến 100 tương ứng với 500 triệu đến 1 tỷ
}

function scaleReverse(value: number) {
  if (value <= 250000000) return value / 10000000;
  if (value <= 500000000) return (value - 250000000) / 10000000 + 25;
  return (value - 500000000) / 20000000 + 50;
}

function valueLabelFormat(value: number) {
  return `${scaleValue(value).toLocaleString()} VNĐ`;
}


const Home: React.FC = () => {
  const dispatch = useDispatch();
  const listAcc = useSelector((state: RootState) => state.listAcc.accPubgs);

  const [searchId, setSearchId] = useState('');
  const [selectedSort, setSelectedSort] = useState<string | null>(null);
  const [value1, setValue1] = useState<number[]>([0, 100]);

  useEffect(() => {
    dispatch(fetchAccPubgs());
  }, [dispatch]);

  const filteredAccs = listAcc
    .filter(acc =>
      (searchId ? acc.account_id.includes(searchId) : true) &&
      acc.price >= scaleValue(value1[0]) && acc.price <= scaleValue(value1[1])
    )
    .sort((a, b) => {
      if (selectedSort === 'price_asc') return a.price - b.price;
      if (selectedSort === 'price_desc') return b.price - a.price;
      if (selectedSort === 'sold') return a.is_buy === b.is_buy ? 0 : a.is_buy ? 1 : -1;
      return 0;
    });

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - 5), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + 5)]);
    }
  };

  return (
    <CustomContainer>
      <Grid>
        <ImageSwiper isPagination={false} />
      </Grid>
      <Grid>
        <Typography variant='h5'>Tìm kiếm</Typography>
        <Grid mt={1} container gap={2} justifyContent='space-between'>
          <TextField
            id="outlined-basic"
            label="Tìm kiếm ID"
            variant="outlined"
            sx={{ width: { xs: '100%', sm: 350, md: 230, lg: 270 } }}
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <GridSearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ width: { xs: '100%', sm: 350, md: 230, lg: 270 } }}>
            <Typography id="non-linear-slider" gutterBottom>
              Giá: {valueLabelFormat(value1[0])} - {valueLabelFormat(value1[1])}
            </Typography>

            <Slider
              getAriaLabel={() => 'Minimum distance'}
              value={value1}
              onChange={handleChange1}
              valueLabelDisplay="auto"
              getAriaValueText={valueLabelFormat}
              valueLabelFormat={valueLabelFormat}
              marks={marks}
              min={0}
              max={100}
              disableSwap
            />
          </Box>
          <Autocomplete
            disablePortal
            id="sort-options"
            options={sortOptions}
            sx={{ width: { xs: '100%', sm: 350, md: 230, lg: 270 } }}
            onChange={(e, newValue) => setSelectedSort(newValue?.value || null)}
            renderInput={(params) => <TextField {...params} label="Sắp xếp" />}
          />
        </Grid>
        <Typography mt={1} variant='h2' textAlign='center' mb={2}>PUBG MOBILE</Typography>
        <CardAcc data={filteredAccs} />
      </Grid>
    </CustomContainer>
  );
};

export default Home;
