// src/App.tsx
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { createTheme, CssBaseline, PaletteMode, ThemeProvider } from '@mui/material';
import * as React from 'react';

import NotFound from './components/NotFound';
import Home from './components/Home';
import Footer from './layout/Footer';
import Header from './layout/Header';
import getLPTheme from './layout/getLPTheme';
import Detail from './components/Detail';
import ComingSoon from './layout/ComingSoon';


const App: React.FC = () => {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Router>
        <Header mode={mode} toggleColorMode={toggleColorMode} />
        <Routes>
          <>
            {/* <Route path="/" element={<ComingSoon />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/detail/:id" element={<Detail />} />
            <Route path="*" element={<ComingSoon />} />
          </>
        </Routes>
        <Footer />
      </Router >
    </ThemeProvider>

  );
};

export default App;
