import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchKeyCheats, updateKeyCheat, addKeyCheat, deleteKeyCheat } from '../thunks/keyCheatThunks';
import { KeyCheatType } from '../../types/keyCheat';

interface KeyCheatState {
  keyCheats: KeyCheatType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: KeyCheatState = {
  keyCheats: [],
  status: 'idle',
  error: null,
};

const keyCheatSlice = createSlice({
  name: 'keyCheats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeyCheats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchKeyCheats.fulfilled, (state, action: PayloadAction<KeyCheatType[]>) => {
        state.status = 'succeeded';
        state.keyCheats = action.payload;
      })
      .addCase(fetchKeyCheats.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(updateKeyCheat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateKeyCheat.fulfilled, (state, action: PayloadAction<KeyCheatType>) => {
        state.status = 'succeeded';
        const index = state.keyCheats.findIndex(keyCheat => keyCheat._id === action.payload._id);
        if (index !== -1) {
          state.keyCheats[index] = action.payload;
        }
      })
      .addCase(updateKeyCheat.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(addKeyCheat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addKeyCheat.fulfilled, (state, action: PayloadAction<KeyCheatType>) => {
        state.status = 'succeeded';
        state.keyCheats.push(action.payload); // Add the new key cheat to the state
      })
      .addCase(addKeyCheat.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(deleteKeyCheat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteKeyCheat.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = 'succeeded';
        state.keyCheats = state.keyCheats.filter(keyCheat => keyCheat._id !== action.payload); // Remove the deleted key cheat
      })
      .addCase(deleteKeyCheat.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      });
  },
});

export default keyCheatSlice.reducer;
