import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchBanners, updateBanner, addBanner, deleteBanner } from '../thunks/bannerThunks';
import { BannerType } from '../../types/banner';

interface BannerState {
  banners: BannerType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: BannerState = {
  banners: [],
  status: 'idle',
  error: null,
};

const bannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBanners.fulfilled, (state, action: PayloadAction<BannerType[]>) => {
        state.status = 'succeeded';
        state.banners = action.payload;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(updateBanner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBanner.fulfilled, (state, action: PayloadAction<BannerType>) => {
        state.status = 'succeeded';
        const index = state.banners.findIndex(banner => banner._id === action.payload._id);
        if (index !== -1) {
          state.banners[index] = action.payload;
        }
      })
      .addCase(updateBanner.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(addBanner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addBanner.fulfilled, (state, action: PayloadAction<BannerType>) => {
        state.status = 'succeeded';
        state.banners.push(action.payload); // Add the new key cheat to the state
      })
      .addCase(addBanner.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      })
      .addCase(deleteBanner.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteBanner.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = 'succeeded';
        state.banners = state.banners.filter(banner => banner._id !== action.payload); // Remove the deleted key cheat
      })
      .addCase(deleteBanner.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Unknown error';
      });
  },
});

export default bannerSlice.reducer;
