import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.css';

interface ImageSwiperProps {
    isPagination: boolean;
}

const ImageSwiper: React.FC<ImageSwiperProps> = ({ isPagination }) => {

    return (
        <>

            <img src='/banner-shop.png' style={{ width: '100%', height: 'auto' }} alt='banner'/>

            {/* {isPagination ?
                <Swiper
                    pagination={pagination}
                    modules={[Pagination, Autoplay]}
                    navigation={true}
                    className="mySwiper"
                    spaceBetween={10} // Khoảng cách giữa các slide
                    slidesPerView={1} // Số lượng slide hiển thị trên một lần
                    autoplay={{ delay: 3000 }} // Thay đổi giá trị delay để điều chỉnh thời gian cuộn
                    loop={true} // Lặp lại các slide
                    style={{ width: '100%', height: 'auto', borderRadius: '12px' }} // Điều chỉnh kích thước của swiper
                >
                    {images.map((image: any, index: any) => (
                        <SwiperSlide key={index}>
                            <img src={image?.image} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto' }} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                :
                <Swiper
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                    spaceBetween={10} // Khoảng cách giữa các slide
                    slidesPerView={1} // Số lượng slide hiển thị trên một lần
                    autoplay={{ delay: 3000 }} // Thay đổi giá trị delay để điều chỉnh thời gian cuộn
                    loop={true} // Lặp lại các slide
                    style={{ width: '100%', height: 'auto', borderRadius: '12px' }} // Điều chỉnh kích thước của swiper
                >
                    {images.length !== 0 ?
                        <>
                            {images.map((image: any, index: any) => (
                                <SwiperSlide key={index}>
                                    <img src={image?.image} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto' }} />
                                </SwiperSlide>
                            ))}
                        </>
                        :
                        <Skeleton height='600px' />
                    }
                </Swiper>
            } */}
        </>
    );
};

export default ImageSwiper;
