// src/features/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  id: string | null;
  isAdmin: boolean;
  token: string | null;
}

const initialState: UserState = {
  id: null,
  isAdmin: false,
  token: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      return { ...state, ...action.payload };
    },
    logout: (state) => {
      return { ...initialState };
    },
  },
});

export const { setUser, logout } = userSlice.actions;
export default userSlice.reducer;
