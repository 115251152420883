import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from '../../utils/axios';
import { AccPubgType, AccPubgTypeAdd } from '../../types/accPubg';

export const fetchAccPubgs = createAsyncThunk(
  'accPubgs/fetchAccPubgs',
  async () => {
    const response = await axiosServices.get('/items'); // Thay đổi URL nếu cần
    return response.data;
  }
);

export const updateAccPubg = createAsyncThunk(
  'accPubgs/updateAccPubg',
  async (accPubg: AccPubgType) => {
    const response = await axiosServices.put(`/items/${accPubg._id}`, accPubg);
    return response.data;
  }
);

export const addAccPubg = createAsyncThunk(
  'accPubgs/addAccPubg',
  async (accPubg: AccPubgTypeAdd) => {
    try {
      const response = await axiosServices.post(`/items`, accPubg);
      return response.data;
    } catch (error) {
      console.error('Error adding key cheat:', error); // Log lỗi nếu có
      throw error; // Đảm bảo lỗi được ném ra để Redux có thể xử lý
    }
  }
);

export const deleteAccPubg = createAsyncThunk(
  'accPubgs/deleteAccPubg',
  async (accPubgId: string) => {
    await axiosServices.delete(`/list-acc/${accPubgId}`);
    return accPubgId;
  }
);