import React from 'react';
import { createRoot } from 'react-dom/client'; // Cập nhật import
import App from './App';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import theme từ theme.ts
import { store } from './store';

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement); // Tạo root container

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
