// src/components/ComingSoon.tsx
import { Box, Typography } from '@mui/material';
import * as React from 'react';

const ComingSoon: React.FC = () => {
    const [timeLeft, setTimeLeft] = React.useState<number>(0);
    const [image, setImage] = React.useState<string>('/assets/comingsoon/pubg-mobile-1.jpg');

    // Mảng chứa các đường dẫn hình ảnh
    const images = [
        '/assets/comingsoon/pubg-mobile-1.jpg',
        '/assets/comingsoon/pubg-mobile-2.jpg'
    ];

    // Chọn ngẫu nhiên một hình ảnh từ mảng

    React.useEffect(() => {
        const targetDate = new Date('2024-08-20T00:00:00').getTime(); // Đặt ngày ra mắt tại đây
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = targetDate - now;

            if (distance < 0) {
                clearInterval(interval);
                setTimeLeft(0);
            } else {
                setTimeLeft(distance);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        const randomImage = images[Math.floor(Math.random() * images.length)];
        setImage(randomImage)
        //  react-hooks/exhaustive-deps
    }, [images]);

    const formatTimeLeft = (milliseconds: number) => {
        const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
        const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            sx={{
                background: `url(${image}) no-repeat center center`,
                backgroundSize: 'cover',
            }}
        >
            <Typography variant="h1" color='#fff' fontSize='120px'>Coming Soon</Typography>
            <Typography mb={10} variant="h2" color='#fff'>{formatTimeLeft(timeLeft)}</Typography>
        </Box>
    );
};

export default ComingSoon;
