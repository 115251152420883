// src/theme.ts
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Màu chính
    },
    secondary: {
      main: '#dc004e', // Màu phụ
    },
    background: {
      default: '#f5f5f5', // Màu nền
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif', // Phông chữ chính
  },
  // Thêm cấu hình khác nếu cần
});

export default theme;
