export const formatCurrency = (value: number) => {
    if (value == null) return 'Chưa có giá'; // Kiểm tra giá trị null hoặc undefined

    // Định dạng số tiền
    const formatter = new Intl.NumberFormat('vi-VN', {
        style: 'decimal',
        minimumFractionDigits: 0, // Số chữ số thập phân là 0
    });

    return `${formatter.format(value)} VNĐ`;
};