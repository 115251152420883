import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="https://www.facebook.com/profile.php?id=100030867711852">Lê Đức Tùng&nbsp;</Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer() {
    const theme = useTheme();
    const color = theme.palette.mode === 'dark' ? '#ffffff' : '#000000';

    return (
        <Box sx={{
            pt: { xs: 4, sm: 8 },
            width: '100%',
            borderTop: '1px solid',
            borderColor: 'divider',
        }}>
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <div>
                        <Link color="text.secondary" href="#">
                            Nhủ Nhuối
                        </Link>
                        <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
                            &nbsp;•&nbsp;
                        </Typography>
                        <Link color="text.secondary" href="#">
                            Lê Đức Tùng
                        </Link>
                        <Copyright />
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="left"
                        spacing={1}
                        useFlexGap
                        sx={{
                            color: 'text.secondary',
                        }}
                    >
                        <IconButton
                            color="inherit"
                            href="https://www.facebook.com/profile.php?id=100030867711852"
                            aria-label="LinkedIn"
                            sx={{ alignSelf: 'center' }}
                        >
                            <FacebookIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href="https://discord.com/users/894026330549669938"
                            aria-label="GitHub"
                            sx={{ alignSelf: 'center' }}
                        >
                            <Box
                                sx={{
                                    width: 24,
                                    height: 24,
                                    backgroundColor: color,
                                    mask: 'url(/assets/discord.svg) no-repeat center',
                                    WebkitMask: 'url(/assets/discord.svg) no-repeat center',
                                }}
                            />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href="https://www.tiktok.com/@nhunhuoi"
                            aria-label="X"
                            sx={{ alignSelf: 'center' }}
                        >
                            <Box
                                sx={{
                                    width: 24,
                                    height: 24,
                                    backgroundColor: color,
                                    mask: 'url(/assets/tiktok.svg) no-repeat center',
                                    WebkitMask: 'url(/assets/tiktok.svg) no-repeat center',
                                }}
                            />
                        </IconButton>
                    </Stack>
                </Box>
            </Container>
        </Box>
    );
}