import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Autoplay, Navigation, Pagination, Thumbs } from 'swiper/modules';
import useImageUrls from '../hook/useImageUrls';

interface ImageSwiperProps {
    images: {
        additional_images: string[];
    }[];
    isPagination: boolean;
}

const ImageSwiper: React.FC<ImageSwiperProps> = ({ images, isPagination }) => {
    const [thumbsSwiper, setThumbsSwiper] = React.useState<any>(null);
    const [currentIndex, setCurrentIndex] = React.useState<number>(0);

    const imageUrls = images[0]?.additional_images || [];
    const { imageUrls: fetchedImageUrls, loading, error } = useImageUrls(imageUrls);

    const handleSlideChange = (swiper: any) => {
        setCurrentIndex(swiper.activeIndex);
    };

    // Ensure useEffect always runs
    const safeImageUrls = Array.isArray(fetchedImageUrls) ? fetchedImageUrls : [];
    
    useEffect(() => {
        if (Number.isNaN(currentIndex)) {
            setCurrentIndex(0);
        }
        
    }, [currentIndex, safeImageUrls.length]); // Use dependencies correctly

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    return (
        <div className="swiper-container-custom">
            <Swiper
                thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
                modules={[Pagination, Autoplay, Navigation, Thumbs]}
                navigation={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }} // Ensure autoplay works
                className="mySwiper"
                spaceBetween={10}
                slidesPerView={1}
                loop={false}
                onSlideChange={handleSlideChange}
                style={{ width: '100%', height: 'auto', borderRadius: '12px' }}
            >
                {safeImageUrls.map((image: string, index: number) => (
                    <SwiperSlide key={index}>
                        <img src={image} alt={`Slide ${index}`} style={{ width: '100%', maxHeight: '460px' }} />
                    </SwiperSlide>
                ))}
            </Swiper>

            {isPagination && (
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={1}
                    slidesPerView={8}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[Thumbs]}
                    className="thumbSwiper"
                    direction="horizontal"
                    style={{ marginTop: '10px' }}
                >
                    {safeImageUrls.map((image: string, index: number) => (
                        <SwiperSlide key={index}>
                            <img src={image} alt={`Thumbnail ${index}`} style={{ width: '100%', height: 'auto' }} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}

            <Grid container alignItems='center' justifyContent='flex-end'>
                {`${currentIndex + 1} / ${safeImageUrls.length}`}
            </Grid>
        </div>
    );
};

export default ImageSwiper;
