import { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../config/firebase-config';

const useImageUrls = (urls: string[]) => {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchImageUrls = async () => {
            if (!urls || urls.length === 0) {
                setImageUrls([]);
                setLoading(false);
                return;
            }

            try {
                const urlPromises: Promise<string>[] = urls.map(async (url) => {
                    const parts = url.split('/');
                    const fileName = parts.pop() || '';
                    const encodedPath = encodeURIComponent(fileName);
                    const urlImage = `https://firebasestorage.googleapis.com/v0/b/image-nhunhuoi.appspot.com/o/${encodedPath}?alt=media`;

                    const pathReference = ref(storage, urlImage);
                    return await getDownloadURL(pathReference);
                });

                const resolvedUrls = await Promise.all(urlPromises);
                setImageUrls(resolvedUrls);
            } catch (err) {
                setError('Error fetching image URLs');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchImageUrls();
    }, [urls]);

    return { imageUrls, loading, error };
};

export default useImageUrls;
